import { AiFillHome } from "react-icons/ai";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { HiAcademicCap } from "react-icons/hi";
import {
  MdPeopleAlt,
  MdClass,
  MdAssessment,
  MdOutlineEmojiTransportation,
} from "react-icons/md";
import { FaChalkboardTeacher } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { BiListCheck } from "react-icons/bi";
import { ImBook } from "react-icons/im";
import { BsFillCalendarDateFill, BsBookmarkStar } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { GrMail, GrMoney } from "react-icons/gr";
import { RiParentLine } from "react-icons/ri";

export const superadminMenuItems = [
  {
    path: "/superadmin/dashboard",
    name: "Dashboard",
    icon: <AiFillHome />,
  },

  {
    path: "/superadmin/packages",
    name: "Packages",
    icon: <AiFillHome />,
  },

  {
    name: "Schools",
    icon: <MdAssessment />,
    submenus: [
      {
        path: "/superadmin/registered-schools",
        name: "Registered Schools",
      },
      {
        path: "/superadmin/add-school",
        name: "Add School",
      },
    ],
  },
  {
    name: "Subscription",
    icon: <MdAssessment />,
    submenus: [
      {
        path: "/superadmin/subscription-report",
        name: "Subscription Report",
      },
      {
        path: "/superadmin/add-school",
        name: "Add School",
      },
    ],
  },
  
];

export const menuItems = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: <AiFillHome />,
  },
  {
    name: "Academics",
    icon: <HiAcademicCap />,
    submenus: [
      {
        path: "/academics/academic-years",
        name: "Academic Years",
      },
      {
        path: "/settings/grading",
        name: "Assessment Grading",
      },
      {
        path: "/teachers/teacher-schedule",
        name: "Teacher's Timetable",
      },
      
    ],
  },
  // {
  //   name: "Parents",
  //   icon: <RiParentLine />,
  //   path: "/parent-management",
    
  // },
  {
    name: "Administrators",
    icon: <MdPeopleAlt />,
    path: "/admin/school-administrators/",
    
  },
  {
    name: "Staffs",
    icon: <FaChalkboardTeacher />,
    path: "/admin/staffs/staff-list"
  },
  {
    name: "Students",
    icon: <MdPeopleAlt />,
    path: "/students/student-list",
    
  },
  // {
  //   name: "Transport",
  //   icon: <MdOutlineEmojiTransportation />,
  //   submenus: [
  //     {
  //       path: "/transport/drivers",
  //       name: "Drivers",
  //     },
  //     {
  //       path: "/transport/Vehicles",
  //       name: "Vehicles",
  //     },
  //     {
  //       path: "/transport/assign-student",
  //       name: "Assign Student",
  //     },
  //   ],
  // },
  {
    name: "Subjects",
    icon: <ImBook />,
    submenus: [
      {
        path: "/subjects/subject-list",
        name: "School Subjects",
      },
      {
        path: "/subjects/class-subjects",
        name: "Class Subjects",
      },
      {
        path: "/subjects/teacher-subjects",
        name: "Teacher Subjects",
      },
    ],
  },
  {
    name: "Class",
    icon: <MdClass />,
    submenus: [
      {
        path: "/class/class-list",
        name: "Classes",
      },
      {
        path: "/class/class-schedule",
        name: "Class Timetable",
      },
      {
        path: "/class/promote-students",
        name: "Promote Students",
      },
    ],
  },

  
  {
    name: "Graduation",
    icon: <FaUserGraduate />,
    submenus: [
      {
        path: "/graduation/graduate-students",
        name: "Graduate Student",
      },
      {
        path: "/graduation/graduation-list",
        name: "Graduation List",
      },
    ],
  },
  {
    name: "Early Years Education",
    icon: <BsBookmarkStar />,
    submenus: [
      {
        path: "/eyfs/objectives",
        name: "Objectives",
      },
      {
        path: "/eyfs/pre-school-report",
        name: "Areas",
      },
      {
        path: "/eyfs/student-report",
        name: "Student Report",
      },
    ],
  },

  {
    name: "Exam & Assessments",
    icon: <MdAssessment />,
    submenus: [
      {
        path: "/assessments/assessment-types",
        name: "Assessment Types",
      },
      {
        path: "/assessments/student-report-scheme",
        name: "Academic Report System",
      },
      {
        path: "/exams/exam-schedule",
        name: "Exam Timetable",
      },
      // {
      //     path:"/assessments/student-assessment-marks",
      //     name: "Student Assessment Marks"
      // },
      {
        path: "/assessments/generate-student-report",
        name: "Generate Student Report",
      },
      {
        path: "/assessments/student-term-marks",
        name: "Student Term Marks",
      },
      {
        path: "/assessments/student-exam-results",
        name: "Terminal Report",
      },
    ],
  },

  {
    name: "Fees Management",
    icon: <RiMoneyDollarCircleLine />,
    submenus: [
      {
        path: "/fees/fees-groups",
        name: "Fee Categories",
      },
      {
        path: "/fees/student-fees-allocation",
        name: "Student Fee Allocation",
      },

      {
        path: "/fees/student-fees",
        name: "Student Fees",
      },
      {
        path: "/fees/fees-debtors",
        name: "Fee Debtors",
      },
    ],
  },
  {
    name: "Collectibles",
    icon: <RiMoneyDollarCircleLine />,
    submenus: [
      {
        path: "/account/Collectibles",
        name: "Collectibles",
      },
      {
        path: "/account/daily-collectibles",
        name: "Daily Collectibles",
      },
    ],
  },

  {
    name: "School Finance",
    icon: <GrMoney />,
    submenus: [
      {
        path: "/finance/overview",
        name: "Overview",
      },
      {
        path: "/finance/income",
        name: "Income",
      },
      {
        path: "/finance/expense",
        name: "Expenses",
      },
      {
        path: "/finance/income-statement",
        name: "Income Statement",
      },
    ],
  },

  {
    name: "Attendance",
    icon: <BiListCheck />,
    submenus: [
      // {
      //     path:'/attendance/class-attendance',
      //     name: "Monthly Attendance Report"
      // },
      {
        path: "/attendance/class-weekly-attendance",
        name: "Weekly Attendance Report",
      },
    ],
  },
  {
    path: "/events",
    name: "Events",
    icon: <BsFillCalendarDateFill />,
  },
  // {
  //   path: "/communications/mailbox",
  //   name: "Mailbox",
  //   icon: <GrMail />,
  // },
  {
    name: "Settings",
    icon: <IoMdSettings />,
    submenus: [
      {
        path: "/settings/school-info",
        name: "School Information",
      },
      
      // {
      //   path: "/settings/subscription",
      //   name: "Subscription",
      // },
    ],
  },
];

export const teacherMenuItems = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <AiFillHome />,
  },
  // {
  //     path: '/admissions',
  //     name: "Admission List",
  //     icon: <HiAcademicCap />,
  // },
  {
    name: "Timetables",
    icon: <ImBook />,
    submenus: [
      {
        path: "/class/class-schedule",
        name: "Class Timetable",
      },
      {
        path: "/teacher/schedule",
        name: "My timetable",
      },
    ],
  },
  {
    name: "Early Years Education",
    icon: <BsBookmarkStar />,
    submenus: [
      {
        path: "/eyfs/objectives",
        name: "Objectives",
      },
      {
        path: "/eyfs/pre-school-report",
        name: "Areas",
      },
      {
        path: "/staff/eyfs-marks",
        name: "Student Marks",
      },
      {
        path: "/eyfs/student-report",
        name: "Student Report",
      },
    ],
  },
  // {
  //   name:"Assignment & Homeworks",
  //   icon:<BiListCheck />,
  //   submenus:[
  //     {
  //       path:"/staff/homeworks",
  //       name:"Homeworks"
  //     }  
  //   ]
  // },
  {
    name: "Exam & Assessments",
    icon: <MdAssessment />,
    submenus: [
      {
        path: "/assessments/assessment-types",
        name: "Assessment Types",
      },
      {
        path: "/assessments/marks-entry",
        name: "Marks Entry",
      },
      {
        path: "/assessments/student-report-scheme",
        name: "Academic Report System",
      },
      {
        path: "/exams/exam-schedule",
        name: "Exam timetable",
      },
      // {
      //     path:"/assessments/student-assessment-marks",
      //     name: "Student Assessment Marks"
      // },
      {
        path: "/assessments/generate-student-report",
        name: "Generate Student Report",
      },
      {
        path: "/assessments/student-term-marks",
        name: "Student Term Marks",
      },
      {
        path: "/assessments/student-exam-results",
        name: "Terminal Report",
      },
    ],
  },

  {
    name: "Attendance",
    icon: <BiListCheck />,
    submenus: [
      {
        path: "/attendance/mark-attendance",
        name: "Mark Attendance",
      },
      {
        path: "/attendance/class-weekly-attendance",
        name: "Weekly Attendance Report",
      },
    ],
  },
  {
    path: "/events",
    name: "Events",
    icon: <BsFillCalendarDateFill />,
  },
  // {
  //   path: "/communications/mailbox",
  //   name: "Mailbox",
  //   icon: <GrMail />,
  // },
];

export const accountantMenuItems = [
  {
    path: "/accountant/dashboard",
    name: "Dashboard",
    icon: <AiFillHome />,
  },
  {
    name: "Classes",
    icon: <MdClass />,
    path: "/class/class-list",
  },

  {
    name: "Students",
    icon: <MdPeopleAlt />,
    path: "/students/student-list",
  },
  {
    name: "Fees Management",
    icon: <RiMoneyDollarCircleLine />,
    submenus: [
      {
        path: "/fees/fees-groups",
        name: "Fee Categories",
      },
      {
        path: "/fees/student-fees-allocation",
        name: "Student Fee Allocation",
      },

      {
        path: "/fees/student-fees",
        name: "Student Fees",
      },
      {
        path: "/fees/fees-debtors",
        name: "Fees Debtors",
      },
      {
        path: "/fees/payments",
        name: "Student Fee Payments",
      },
    ],
  },
  {
    name: "Collectibles",
    icon: <RiMoneyDollarCircleLine />,
    submenus: [
      {
        path: "/account/Collectibles",
        name: "Collectibles",
      },
      {
        path: "/account/daily-collectibles",
        name: "Daily Collectibles",
      },
    ],
  },
  {
    name: "School Finance",
    icon: <GrMoney />,
    submenus: [
      {
        path: "/finance/overview",
        name: "Overview",
      },
      {
        path: "/finance/income",
        name: "Income",
      },
      {
        path: "/finance/expense",
        name: "Expenses",
      },
      {
        path: "/finance/income-statement",
        name: "Income Statement",
      },
    ],
  },
  {
    path: "/events",
    name: "Events",
    icon: <BsFillCalendarDateFill />,
  },
  // {
  //   path: "/communications/mailbox",
  //   name: "Mailbox",
  //   icon: <GrMail />,
  // },
  // {
  //   name: "Settings",
  //   icon: <IoMdSettings />,
  //   submenus: [
  //     {
  //       path: "/settings/subscription",
  //       name: "Subscription Payment",
  //     },
  //   ],
  // },
];

export const studentMenuItems = [
  {
    path: "/std/dashboard",
    name: "Dashboard",
    icon: <AiFillHome />,
  },
  {
    name: "My Teachers",
    icon: <MdClass />,
    path: "/std/teachers",
  },
  {
    name: "My Class",
    icon: <MdClass />,
    path: "/std/student-class",
  },
  {
    name: "My Attendance",
    icon: <MdClass />,
    path: "/student-attendance",
  },

  {
    name: "Exams",
    icon: <MdAssessment />,
    submenus: [
      {
        path: "/student/report-card",
        name: "Terminal Report",
      },
      {
        path: "/student/exam-schedule",
        name: "Exam Timetable",
      },

      {
        path: "/student/main-exam-marks",
        name: "Main Exam Marks",
      },
    ],
  },
  {
    path: "/student/fees",
    name: "My Fees",
    icon: <RiMoneyDollarCircleLine />,
  },

  {
    path: "/events",
    name: "Events",
    icon: <BsFillCalendarDateFill />,
  },
  // {
  //   path: "/communications/mailbox",
  //   name: "Mailbox",
  //   icon: <GrMail />,
  // },
];
