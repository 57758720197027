import React, { useEffect, useState } from "react";

import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { API_URL } from "../../url";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useAuthenticate } from "../../context/AuthContext";
import Header from "../../components/Header";
import LoadingModal from "../../components/LoadingPage/LoadingModal";



const Events = () => {
  const { user } = useAuthenticate();
  const [loadingData, setLoadingData] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');  // Track the search query
  const [totalPages, setTotalPages] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [deleting,setDeleting] = useState(false)


  
  

  const filterEvents = (searchValue) => {
    setSearch(searchValue);
    setCurrentPage(1);  // Reset to page 1 whenever search query changes
  };

  const fetchEvents = async (page, searchQuery) => {
    if (localStorage.getItem("access")) {
      setLoadingData(true);
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const response = await axios.get(`${API_URL}administrator/events/?page=${page}&search=${searchQuery}`, config);
        setLoadingData(false);
        setEvents(response.data?.results);
        setNextPage(response.data?.next);
        setPrevPage(response.data?.previous);
        setTotalPages(Math.ceil(response.data?.count / 20));
        setLoadingData(false)
      } catch (error) {
        setLoadingData(false);
      }
    }
  };

  

  const handleNextPage = () => {
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (prevPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
};

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalNumbersToShow = 3; // Number of pages to show around the current page
    const totalPagesToShow = 5; // Total pages to show at start and end

    let startPage = Math.max(1, currentPage - totalNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + totalNumbersToShow);

    // Always show the first page
    if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) {
            pageNumbers.push('...');
        }
    }

    // Show the numbers around the current page
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // Always show the last page
    if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
            pageNumbers.push('...');
        }
        pageNumbers.push(totalPages);
    }

    return pageNumbers.map(number => (
        <button
            key={number}
            onClick={() => number !== '...' && handlePageClick(number)}
            className={number === currentPage ? 'bg-secondary border border-secondary text-white rounded py-2 px-3 min-w-10' : 'border border-secondary text-main bg-transparent py-2 px-3 rounded min-w-10'}
        >
            {number}
        </button>
    ));
};

const deleteEvent = (event_id) => {
  Swal.fire({
    title: `Are you sure?`,
    text: "You are about to delete this event. ",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      setDeleting(true)
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": `application/json`,
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        axios
          .delete(`${API_URL}administrator/events/${event_id}/`, config)
          .then((response) => {
            setDeleting(false)
            Swal.fire({
              title: "Deleted!",
              text: "Event has been deleted.",
              icon: "success",
              timer: 1500,
            }).then(() => {
              window.location.href = "/events"
            });
          })
          .catch((error) => {
            setDeleting(false)
            if (error.response) {
              if(error.response.status === 400){
                for (const property in error.response.data) {
                  toast.error(
                    `${property} error: ${error.response.data[property]}`
                  );
                }
              }else{
                toast.error("Something went wrong. Please try again later.")
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }
          });
      }
    }
  });
};

  useEffect(() => {
    fetchEvents(currentPage, search);
  }, [currentPage,search]);
  return (
    <div className="main-container">
      <Header title="School Events" subtitle="Keep track of school events"/>
      {
        deleting && (
          <LoadingModal text="Deleting Event" />
        )
      }
      <div className="flex flex-col lg:flex-row justify-between lg:items-center gap-5">
        <div className="flex flex-col lg:flex-row lg:items-center gap-2">
          <input
            type="text"
            placeholder="Search event"
            className="px-[10px] py-2 border border-gray-300 outline-none text-[12px]"
            onChange={(e) => filterEvents(e.target.value)}
          />

          
        </div>
        {
          user?.account_type === "School Administrator" && (
            <div className="flex">
              
              <Link
                to="/new-event"
                className="px-[10px] py-2 text-[12px] border border-light-primary bg-light-primary text-white "
              >
                Create Event
              </Link>
            </div>
          )
        }
      </div>

      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            {!loadingData && (
              <table className="min-w-full text-left text-sm font-light text-surface ">
                <thead className=" w-screen  font-medium">
                  <tr className="border-b border-t border-slate-200 text-black">
                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Event Title
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Date
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Venue
                    </th>

                    

                    <th
                      scope="col"
                      className="px-6 py-2 font-[500] text-[12px]"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {events?.map((event, index) => (
                    <tr
                      key={index}
                      className="text-[12px] border-l-2 border-transparent py-3 hover:border-primary hover:bg-slate-50 "
                    >
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {event?.title}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {event?.date}
                      </td>
                      <td className="px-4 py-3 font-[400] border-r border-slate-300 mt-2">
                        {event?.venue}
                      </td>
                      

                      <td className="flex gap-2 py-3 items-center px-4">
                        <Link
                          to={`/events/${event?.id}/edit`}
                          className=" text-[12px] w-max px-[10px] py-1 font-[500] border border-blue-400 hover:text-blue-400 hover:bg-white  bg-blue-400 text-white cursor-pointer"
                        >
                          Edit
                        </Link>
                        <Link
                          to={`/events/${event?.id}`}
                          className=" text-[12px] w-max px-[10px] py-1 font-[500] border border-blue-400 text-blue-400  hover:bg-blue-400 hover:text-white cursor-pointer"
                        >
                          Details
                        </Link>
                        <div
                          onClick={()=>deleteEvent(event?.id)}
                          className=" text-[12px] w-max px-[10px] py-1 font-[500] border border-red-400 text-red-400  hover:bg-red-400 hover:text-white cursor-pointer"
                        >
                          Delete
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {loadingData && (
              <div className="flex items-center justify-center py-5">
                <RotatingLines
                  visible={true}
                  height="26"
                  width="26"
                  strokeColor="black"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
            {!loadingData && events.length == 0 && (
              <p className="text-surface text-gray-400 py-10 text-center">
                No Event found.
              </p>
            )}
          </div>
        </div>
      </div>
      {
        events?.length > 0 && (
          <div className="flex justify-between items-center mt-10">
            <p>
              Page {currentPage} of {totalPages}
            </p>

            <div className="flex flex-col gap-2 items-start md:px-5 md:flex-row">
              <button className={`border border-primary py-2 px-4 ${!prevPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handlePreviousPage} disabled={!prevPage}>
                Previous
              </button>
              {renderPageNumbers()}
              <button className={`border border-primary py-2 px-4 ${!nextPage ? "text-primary bg-white":"text-white bg-primary"}`} onClick={handleNextPage} disabled={!nextPage}>
                Next
              </button>
              
            </div>
          </div>
        )
      }

    </div>
  );
};

export default Events;
