import React, { useState, useEffect } from "react";

import "./Students.scss";
import axios from "axios";
import Swal from "sweetalert2";
import { API_URL } from "../../url";
import { ColorRing } from "react-loader-spinner";
import FormInput from "../../components/Forms/FormInput";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ImageUpload from "../../assets/avatar.jpg";
import Header from "../../components/Header";

const AddStudent = () => {
  const [file, setFile] = useState("");
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    profile_picture: file,
    first_name: "",
    last_name: "",
    gender: "",
    date_of_birth: "",
    phone_number: "",
    email: "",
    residential_address: "",
    religion: "",
    father_name: "",
    mother_name: "",
    father_phone_number: "",
    mother_phone_number: "",
    guardian: "",
    guardian_relation: "",
    guardian_phone_number: "",
    admission_id: "",
    roll_number: "",
    student_class: "",
    index_number: "",
    admission_date: "",
    username: "",
    password: "",
  });

  // Generate random password
  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";

    const passwordLength = 8;

    let generatedPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      generatedPassword += characters[randomIndex];
    }

    setData({
      ...data,
      password: generatedPassword,
    });
  };

  // handle field changes
  const onChange = (e) =>
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loading) {
      data.profile_picture = file;
      if (data.profile_picture && data.profile_picture.size > 878576) {
        toast.error(
          "The size of the profile picture should be less than 878KB"
        );
      } else {
        if (localStorage.getItem("access")) {
          setLoading(true);
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };

          const formData = new FormData();
          Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
          });
          try {
            const res = await axios.post(
              `${API_URL}administrator/students/`,
              formData,
              config
            );

            setLoading(false);
            if (res.status === 201) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Student Created Successfully",
              }).then(() => {
                window.location.reload();
              });
            }
          } catch (error) {
            console.log(error);
            if (error.response) {
              if (error.response.status === 401) {
                window.location.href("/");
              } else if (error.response.status === 400) {
                for (const property in error.response.data) {
                  if (property === "0") {
                    toast.error(`${error.response.data[property]}`);
                  } else {
                    toast.error(
                      `${property} error: ${error.response.data[property]}`
                    );
                  }
                }
              } else {
                toast.error("Something went wrong. Please try again");
              }
            } else if (error.message) {
              toast.error("Something went wrong. Please try again");
            }

            setLoading(false);
          }
        }
      }
    }
  };

  // COMPRESS FILE
  const compressFile = (image) => {
    let file_name = image.name;
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      let image_url = event.target.result;
      let image = document.createElement("img");
      image.src = image_url;

      image.onload = (e) => {
        // creating a new canvas for the image
        const canvas = document.createElement("canvas");
        let ratio = 300 / e.target.width;
        canvas.width = 300;
        canvas.height = ratio * e.target.height;

        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        // converting the image quality to 50% and the file type to webp
        let new_image_url = canvas.toDataURL("image/webp", 50);

        // getting the image type and the data
        let arr = new_image_url.split(",");
        let image_type = arr[0].match(/:(.*?);/)[1];
        let data = arr[1];
        let dataStr = atob(data);
        let n = dataStr.length;
        let dataArr = new Uint8Array(n);

        while (n--) {
          dataArr[n] = dataStr.charCodeAt(n);
        }

        const compressed_image = new File([dataArr], file_name, {
          type: image_type,
        });
        setFile(compressed_image);
      };
    };
  };

  useEffect(() => {
    // Get school classes
    const fetchSchoolClasses = async () => {
      if (localStorage.getItem("access")) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };
        try {
          const res = await axios.get(`${API_URL}academics/classes/`, config);
          setSchoolClasses(res.data);
        } catch (error) {
          if (error.response.status === 401) {
            window.location.href = "/";
          }
        }
      }
    };
    fetchSchoolClasses();
  }, []);

  return (
    <div className="main-container">
      <Header title="Add Student" subtitle="Student Management" />
      <div className="bottom">
        <div className="right">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="formSection">
              <p className="my-5 bg-blue-300 p-2">Student Personal Info</p>
              <div className="formRow">
                <div className="flex">
                  <img
                    src={file ? URL.createObjectURL(file) : ImageUpload}
                    alt="student"
                    className="h-[100px] w-[100px]"
                  />
                </div>
              </div>
              <div className="formRow">
                <div className="formInput">
                  <label>Student Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_picture"
                    id="profile_picture"
                    // onChange={(e) => {
                    //   setFile(e.target.files[0]);
                    //   setData({
                    //     ...data,
                    //     [e.target.name]: e.target.files[0],
                    //   });
                    // }}
                    onChange={(e) => {
                      compressFile(e.target.files[0]);
                    }}
                  />
                  <label id="upload-button" htmlFor="profile_picture">
                    Upload File
                  </label>
                  <p style={{ fontWeight: "400", fontSize: "12px" }}>
                    {data.profile_picture
                      ? data.profile_picture.name
                      : "No file selected"}
                  </p>
                </div>

                <FormInput
                  label="First Name*"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="first_name"
                  id="first_name"
                  required={true}
                  // pattern="^[A-Za-z\s]{3,30}$"
                  errorMessage="Should be at least 3 characters long with no special
                    characters"
                />

                <FormInput
                  label="Last Name*"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="last_name"
                  id="last_name"
                  required={true}
                  // pattern="^[A-Za-z\s]{3,16}$"
                  errorMessage="Should be at least 3 characters long with no number or special
        characters"
                />
              </div>
              <div className="formRow">
                <div className="formInput">
                  <label>Gender*</label>
                  <div className="radioInline">
                    <input
                      type="radio"
                      name="gender"
                      required
                      onChange={(e) => onChange(e)}
                      value="Male"
                    />{" "}
                    Male
                  </div>
                  <div className="radioInline">
                    <input
                      type="radio"
                      name="gender"
                      onChange={(e) => onChange(e)}
                      value="Female"
                    />{" "}
                    Female
                  </div>
                </div>

                <FormInput
                  label="Date of Birth*"
                  type="date"
                  onChange={(e) => onChange(e)}
                  name="date_of_birth"
                  id="date_of_birth"
                  required={true}
                />

                <div className="formInput">
                  <label>Phone Number</label>
                  <PhoneInput
                    defaultCountry="GH"
                    className="phone-input"
                    value={data.phone_number}
                    onChange={(value) =>
                      setData({
                        ...data,
                        phone_number: value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="formRow">
                <FormInput
                  label="Email Address"
                  type="email"
                  onChange={(e) => onChange(e)}
                  name="email"
                  id="email"
                  errorMessage="Please enter a valid email address"
                />

                <FormInput
                  label="Residential Address*"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="residential_address"
                  id="residential_address"
                  required={true}
                  errorMessage="Please enter a valid residential address"
                />

                <FormInput
                  label="Religion (Denomination)"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="religion"
                  id="religion"
                />
              </div>

              <div className="formRow">
                <FormInput
                  label="Father's Name"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="father_name"
                  id="father_name"
                  pattern="^[A-Za-z ]{3,30}$"
                  errorMessage="Enter A Valid Name"
                />

                <div className="formInput">
                  <label>Father's Phone Number</label>
                  <PhoneInput
                    defaultCountry="GH"
                    className="phone-input"
                    value={data.father_phone_number}
                    onChange={(value) =>
                      setData({
                        ...data,
                        father_phone_number: value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="formRow">
                <FormInput
                  label="Mother's Name"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="mother_name"
                  id="mother_name"
                  // pattern="^[A-Za-z ]{3,30}$"
                  errorMessage="Enter A Valid Name"
                />

                <div className="formInput">
                  <label>Mother's Phone Number</label>
                  <PhoneInput
                    defaultCountry="GH"
                    className="phone-input"
                    value={data.mother_phone_number}
                    onChange={(value) =>
                      setData({
                        ...data,
                        mother_phone_number: value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="formRow">
                <div className="formInput">
                  <label>Guardian Name</label>
                  <input
                    type="text"
                    name="guardian"
                    onChange={(e) => onChange(e)}
                    id=""
                  />
                </div>
                <div className="formInput">
                  <label>Guardian Phone Number</label>

                  <PhoneInput
                    defaultCountry="GH"
                    className="phone-input"
                    value={data.guardian_phone_number}
                    onChange={(value) =>
                      setData({
                        ...data,
                        guardian_phone_number: value,
                      })
                    }
                  />
                  <small>
                    We will send the student's credentials to the guardian. If
                    only you have enough sms credit left
                  </small>
                </div>
                <div className="formInput">
                  <label>Guardian Relation</label>
                  <input
                    type="text"
                    onChange={(e) => onChange(e)}
                    name="guardian_relation"
                  />
                </div>
              </div>
            </div>

            <div className="formSection">
              <p className="my-5 bg-blue-300 p-2">Student Academic Info</p>
              <div className="formRow">
                <div className="formInput">
                  <label>Admission ID</label>
                  <input
                    type="text"
                    name="admission_id"
                    onChange={(e) => onChange(e)}
                  />
                  <small>
                    Provide the ID of the student if the student already has an
                    ID. Leave it blank for the system to auto generate an ID for
                    the student
                  </small>
                </div>

                <div className="formInput">
                  <label>Index Number</label>
                  <input
                    type="text"
                    name="index_number"
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <div className="formRow">
                <div className="formInput">
                  <label>Admission Date</label>
                  <input
                    type="date"
                    name="admission_date"
                    onChange={(e) => onChange(e)}
                  />
                </div>

                <div className="formInput">
                  <label>Class</label>
                  <select
                    name="student_class"
                    onChange={(e) => onChange(e)}
                    required
                  >
                    <option>Select Class</option>
                    {schoolClasses.map((schoolClass) => {
                      return (
                        <option
                          key={schoolClass.id}
                          value={parseInt(schoolClass.id)}
                        >
                          {schoolClass.class_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="formSection">
              <p className="my-5 bg-blue-300 p-2">Student Account Info</p>
              <div className="formRow">
                <FormInput
                  label="Username*"
                  type="text"
                  onChange={(e) => onChange(e)}
                  name="username"
                  id="username"
                  pattern="^[A-Za-z0-9]{5,16}$"
                  required={true}
                  errorMessage="Should be at least 5 characters with no special characters"
                  infoMessage="Please Note that username is case sensitive"
                />

                <div className="formInput">
                  <label>Password*</label>
                  <input
                    type="text"
                    name="password"
                    value={data.password}
                    required
                    onChange={(e) => onChange(e)}
                  />
                </div>

                <br />
                <div className="formInput">
                  <label htmlFor=""></label>
                  <div
                    onClick={() => generatePassword()}
                    className="bg-secondary-100 px-5 py-3 rounded text-white cursor-pointer w-max mt-5"
                  >
                    Generate Password
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <button style={{ cursor: "wait", backgroundColor: "gray" }}>
                <ColorRing
                  visible={true}
                  height="20"
                  width="20"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
                Adding Student...
              </button>
            ) : (
              <button>Add Student</button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddStudent;
