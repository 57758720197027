import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { API_URL } from "../../url";
import { Link, useParams } from "react-router-dom";
import { useAuthenticate } from "../../context/AuthContext";
import Header from "../../components/Header";
import Loading from "../../components/LoadingPage/Loading";

const EditEvent = () => {
    const { id } = useParams();
  const { user } = useAuthenticate();
  const [loading, setLoading] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [event, setEvent] = useState({});

  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      const body = JSON.stringify(data);
      try {
        const res = await axios.put(
          `${API_URL}administrator/events/${id}/`,
          body,
          config
        );

        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Event updated Successfully",
        }).then(() => {
          window.location.href = "/events";
        });
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = "/";
          } else if (error.response.status === 400) {
            for (const property in error.response.data) {
              toast.error(
                `${property} error: ${error.response.data[property]}`
              );
            }
          } else {
            toast.error("Something went wrong. Please try again");
          }
        } else if (error.message) {
          toast.error("Something went wrong. Please try again");
        }
      }
    }
  };

  const fetchEvent = async (event_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}administrator/events/${event_id}`,
          config
        );
        setEvent(res.data);
        setLoadingEvent(false);
      } catch (error) {
        setLoadingEvent(false);
      }
    }
  };

  useEffect(() => {
    fetchEvent(id);
  }, []);

  return (
    <div className="main-container">
      <Header title="Edit Event" subtitle="School Events" />
      {
        !loadingEvent ? (
            <div className="">
                <form onSubmit={(e) => handleSubmit(e)}>
                <div className="flex flex-col gap-1 mt-3">
                    <label className="font-[500]">Title</label>
                    <input
                    type="text"
                    name="title"
                    value={event?.title ? event?.title : data?.title}
                    className="border border-gray-300 rounded py-2 px-3"
                    onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="flex flex-col gap-1 mt-3">
                    <label className="font-[500]">Description*</label>

                    <CKEditor
                    style={{ marginTop: "10px" }}
                    editor={ClassicEditor}
                    data={event?.description ? event?.description : data?.description}
                    onChange={(event, editor) => {
                        const text = editor.getData();
                        setData({
                        ...data,
                        description: text,
                        });
                    }}
                    />
                </div>
                <div className="flex flex-col gap-1 mt-3">
                    <label className="font-[500]">Date*</label>
                    <input
                    type="date"
                    name="date"
                    id="date"
                    value={event?.date ? event?.date : data?.date}
                    className="border border-gray-300 rounded py-2 px-3"
                    onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="flex flex-col gap-1 mt-3">
                    <label className="font-[500]">Time*</label>
                    <input
                    type="time"
                    name="time"
                    id="time"
                    value={event?.time ? event?.time : data?.time}
                    className="border border-gray-300 rounded py-2 px-3"
                    onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="flex flex-col gap-1 mt-3">
                    <label className="font-[500]">Venue</label>
                    <input
                    type="text"
                    name="venue"
                    id="venue"
                    value={event?.venue ? event?.venue : data?.venue}
                    className="border border-gray-300 rounded py-2 px-3"
                    onChange={(e) => handleChange(e)}
                    />
                </div>
                {user.account_type === "School Administrator" && (
                    <div className="flex justify-end mt-3">
                    {loading ? (
                        <button className="bg-gray-500 rounded cursor-wait flex items-center gap-2 px-3 py-2 text-white">
                        <ColorRing
                            visible={true}
                            height="20"
                            width="20"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />{" "}
                        Updating Event{" "}
                        </button>
                    ) : (
                        <button
                        type="submit"
                        className="bg-primary px-3 py-2 rounded text-white"
                        >
                        Update Event
                        </button>
                    )}
                    </div>
                )}
                </form>
            </div>
        ):(
            <Loading />
        )
      }
    </div>
  );
};

export default EditEvent;
