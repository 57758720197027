import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import "./Events.scss";
import axios from "axios";
import parse from "html-react-parser";
import { API_URL } from "../../url";
import { ProgressBar } from "react-loader-spinner";
import Logo from "../../assets/logos/default-logo.png";

const EventDetail = () => {
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [events, setEvents] = useState([]);
  const [loadingEvent, setLoadingEvent] = useState(true);

  const fetchEvent = async (event_id) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}administrator/events/${event_id}`,
          config
        );
        setEvent(res.data);
        setLoadingEvent(false);
      } catch (error) {
        setLoadingEvent(false);
      }
    }
  };
  useEffect(() => {
    

    fetchEvent(id);
  }, []);

  return (
    <>
      {!loadingEvent ? (
        <div className="main-container event-detail-container">
          <div className="event-left">
            <div className="header">
              <h1>{event.title}</h1>
            </div>
            <h5>
              Date: {event.date} {event.time}
            </h5>
            <h5>Venue: {event.venue}</h5>
            <br />
            <p>{parse(event.description)}</p>
          </div>
          
        </div>
      ) : (
        <div className="progress-bar">
          <img src={Logo} className='h-20' />
          <ProgressBar
            height="250"
            width="350"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#002057"
            barColor="#51E5FF"
          />
        </div>
      )}
    </>
  );
};

export default EventDetail;
