import { motion } from "framer-motion";

const LoadingModal = ({text}) => {
  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center"
      >
        <div className="loader border-t-4 border-blue-500 border-solid rounded-full w-12 h-12 animate-spin"></div>
        <p className="mt-4 text-gray-700">{text}</p>
      </motion.div>
    </div>
  );
};

export default LoadingModal;
