import React, { useState } from "react";
import { useEffect } from "react";
import "./subscription.scss";
import { API_URL } from "../../url";
import axios from "axios";
import Loading from "../../components/LoadingPage/Loading";
import MakeSubcriptionPayment from "./MakeSubscriptionPayment"
import { toast } from "react-toastify";

const Subscription = () => {
  const [schoolSubscription, setSchoolSubscription] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null)
  const [gettingSubscriptionData, setGettingSubscriptionData] = useState(false)

  const getSchoolSubscription = async () => {
    if (localStorage.getItem("access")) {
      setGettingSubscriptionData(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}subscription/school/`,
          config
        );
        if(res?.data?.success){

          setSchoolSubscription(res.data?.payload);
        }
        setGettingSubscriptionData(false)
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
        setGettingSubscriptionData(false)
      }
    }
  };

  const getSchoolActiveSubscription = async () => {
    if (localStorage.getItem("access")) {
      setGettingSubscriptionData(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };
      try {
        const res = await axios.get(
          `${API_URL}subscription/school/active/`,
          config
        );
        if(res?.data?.success){
          setActiveSubscription(res.data?.payload);
        }
        setGettingSubscriptionData(false)
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = "/";
        }else if(error.response.status === 404){
          toast.error("No subscription available")
          window.location.href = "/settings/subscription/select-plan/"
        }
        setGettingSubscriptionData(false)
      }
    }
  };

  useEffect(() => {
    getSchoolActiveSubscription();
  }, []);

  return (
    <div className="main-container">
      <div className="header">
        <h1>SUBSCRIPTIONS</h1>
      </div>
      
    </div>
  );
};

export default Subscription;
