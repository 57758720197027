import React from 'react'
import Header from '../../../components/Header'

const Homework = () => {
  return (
    <div className='main-container'>
        <Header title="Homework" subtitle="Manage Homework" />

        <div className="rounded shadow-md">
            <div className="flex justify-between">
                <button>
                    Add
                </button>
            </div>
        </div>
    </div>
  )
}

export default Homework