import React, { useEffect, useState } from 'react'
import { API_URL } from '../../url';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../components/LoadingPage/Loading';
import LoadingModal from '../../components/LoadingPage/LoadingModal';
import Swal from 'sweetalert2';

const SubscriptionPackages = () => {
    const [plans,setPlans] = useState([])
    const [gettingPlans, setGettingPlans] = useState(false)
    const [subscribing, setSubscribing] = useState(false)

    const getPlans = async () => {
        if (localStorage.getItem("access")) {
          setGettingPlans(true)
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
              Accept: "application/json",
            },
          };
          try {
            const res = await axios.get(
              `${API_URL}subscription/packages/list/`,
              config
            );
            if(res?.data?.success){
                setPlans(res.data?.payload);
            }
            setGettingPlans(false)
          } catch (error) {
            if (error.response.status === 401) {
              window.location.href = "/";
            }
            setGettingPlans(false)
          }
        }
      };

    const subscribePackage = async (subscription_package) => {
        if(subscription_package?.package_type == "Free"){
            if (localStorage.getItem("access")) {
                setSubscribing(true)
                const config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("access")}`,
                    Accept: "application/json",
                  },
                };
                try {
                    const body = JSON.stringify({
                        package:subscription_package?.id
                    })
                  const res = await axios.post(
                    `${API_URL}subscription/school/subscribe/`, body,
                    config
                  );
                  Swal.fire({
                    icon:"success",
                    title:"Subscription successfull",
                    text: "You have successfully subscribed to the free package",

                  }).then(()=>{
                    window.location.href = "/admin/dashboard"
                  })
                  setSubscribing(false)
                } catch (error) {
                    if (error.response.status === 401) {
                        window.location.href = "/";
                    }else if(error.response.status === 400){
                      toast.error(error.response.data.message)
                      for (const property in error.response.data.error) {
                        if (property === "0") {
                          toast.error(`${error.response.data.error[property]}`);
                        } else {
                          toast.error(
                            `${property} error: ${error.response.data.error[property]}`
                          );
                        }
                      }
                      
                  }
                  setSubscribing(false)
                }
              }
        }
    }

    useEffect(()=>{
        getPlans();
    },[])
   
  return (
    <div className="main-container">
        <div className="text-center my-10">
            <h6 className="uppercase font-[600] text-green-700">Pricing</h6>
            <h3 className="text-[25px] font-[700]">Affordable Pricing suitable for your needs</h3>
            <p>Select a plan that serves the needs of your school and lets go.</p>
        </div>
        {
            subscribing && (
                <LoadingModal text="Subscribing..." />
            )
        }

        {
            gettingPlans ? (
                <Loading />
            ):(
                <div className="flex">
                    {plans.map((plan, index)=>(
                        <div key={index} className=" w-1/2 border border-gray-300">
                            <div className="">
                                <div className="py-3">
                                    <div className="px-5 py-3 border-b border-gray-300">
                                        <h5 className="text-2xl font-bold">{plan.name}</h5>
                                        <h6 className="text-xl font-semibold">{plan.price}<span className='font-[400] text-[15px]'> for {plan.duration}</span></h6>
                                        <button onClick={()=>subscribePackage(plan)} className='bg-blue-600 hover:bg-blue-800 rounded text-white px-4 py-2 mt-5'>Choose Package</button>
                                    </div>
                                    
                                    <ul>
                                        {plan.features.map((feature, index)=>(
                                            <li className='border-b border-gray-300 p-5' key={index}>{feature}</li>
                                        ))}
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
        }

    </div>
  )
}

export default SubscriptionPackages